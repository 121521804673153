import React, { useState } from 'react';
import './FAQ.css';
import AccordinBox from '../../../PersonalFunction/AccordinBox';
import Flash2_TextEZ02_Icon from '../../../PublicAssets/Arrow_Icons/Flash2_TextEZ02_Icon.svg';
import { AnimatePresence, motion } from 'framer-motion';

function FAQ() {

    const Answer4 = () => {
        return (
            <>
                مهندسین و پیمانکارانی که در EliteZone  ثبت نام می کنند پس از ورود به سامانه از طریق پروفایل خود به اطلاعات فنی و قیمت های مصالح ساختمانی دسترسی پیدا می کنند. پس از آن هنگام مراجعه کارفرما به دفتر مهندسی یا به هر نحو که صلاح می دانند، با ارائه توضیحات فنی لازم خرید مصالح ساختمانی موجود را به کارفرما پیشنهاد داده و مشخصات کارفرما را در پنل خود درج می کنند.
                از سوی دیگر هنگامی که کارفرما قصد خرید مصالح ساختمانی از EliteZone را دارد، برای استفاده از قیمت های ویژه، لازم است یک مهندس یا پیمانکار را به عنوان مشاور انتخاب کند و با توجه به اینکه قبلا مشخصات کارفرما در پنل مهندس یا پیمانکار ثبت شده است، همین مهندس یا پیمانکار در پنل کارفرما به عنوان مشاور به او معرفی می شود تا با انتخاب او بتواند از قیمت های ویژه الیت زون استفاده کند.
                درصورت انجام فرایند خرید توسط کارفرما، مهندس یا پیمانکاری که هنگام خرید توسط کارفرما به عنوان مشاور انتخاب می شود، هزینه مشاوره خود را مستقیما از طریق EliteZone دریافت می کند.
            </>
        )
    }

    const Answer8 = () => {
        return (
            <>
                مخاطبان ارجمند لازم است به این نکته توجه بفرمایند که «عضویت در الیت‌زون» و «بهره‌مندی از مزایای الیت‌زون» دو مقوله‌ی متفاوت‌اند؛ برای عضویت در الیت‌زون، به هیچ‌گونه پروانه‌ی اشتغال یا مدرکی به‌جز یک شماره‌ی تلفن همراه نیاز نیست، به این صورت که شما با وارد کردن شماره‌ی همراه خودتان و انتخاب یکی از نقش‌ها (مهندس، پیمانکار، سوپلایر یا کارفرما)، ثبت‌نام نموده و به عضویتِ الیت‌زون درمی‌آیید؛ اما استفاد از مزایای الیت‌زون ویژه‌ی مهندسانِ دارای پروانه‌ی اشتغال و پیمانکارانِ ذیصلاح است، به این ترتیب که مهندسانِ دارای پروانه‌ی اشتغال و پیمانکارانِ ذیصلاح می‌توانند برای خودشان یا برای دیگران، از مصالح ساختمانی که در الیت‌زون ارائه خواهد شد، خرید کنند.
            </>
        )
    }

    const Answer9 = () => {
        return (
            <>
                موضوع عضویت در سایت الیت‌زون یا بهره‌مندی از مزایای آن ربطی به نرم‌افزار سازه۹۰ یا کاربرِ سازه۹۰ بودن یا نبودن ندارد و هر کسی که مهندس یا پیمانکار یا سوپلایر یا کارفرما باشد، می‌تواند عضو الیت‌زون شود. همچنین، مهندسانِ دارای پروانه‌ی اشتغال و پیمانکارانِ ذیصلاح بر اساس پروانه‌ی اشتغال به کار مهندسی که در آن عبارت «پیمانکار ذیصلاح» قید شده باشد، یا بر اساس ارزیابی‌هایی که ما بعداً ملاک‌هایش را اعلام خواهیم کرد، می‌توانند از مزایای الیت‌زون استفاده کنند. البته در این فرآیند، ما برای کاربران نرم‌افزار سازه۹۰ آپشن‌ها و شرایط خاصی را در نظر خواهیم گرفت که در آینده این موارد را به اطلاع مخاطبان ارجمند خواهیم رساند.
            </>
        )
    }

    const Answer10 = () => {
        return (
            <>
                خوشبختانه چون دست‌اندرکارانِ الیت‌زون برآمده از جامعه‌ی مهندسی ایران هستند، با استانداردها، آزمایش‌ها و ملاکهایی که مصالح مرغوب را از مصالح نامرغوب جدا و متمایز می‌کنند، کاملاً آشنا هستند، که از جمله‌ی این استانداردها می‌توانیم استانداردهای یورو، استانداردهای BS و استانداردهای ASTN را نام ببریم. افزون بر این، برخی از سوپلایرهایی که ما تا کنون با آن‌ها مذاکره کرده‌ایم و یا در حال گفت‌وگو یا در شُرفِ عقد قرار داد با آن‌ها هستیم، اساساً استانداردهای تولیداتشان را از منابع استاندارد جهانی (مانند بریتیش‌استاندارد یا از یورواستاندارد) گرفته‌اند و حتی بر اساس این استانداردها، مساله ساختمانیِ تولیدیِ خودشان را به دیگر کشورهای جهان هم صادر می‌کنند. علاوه بر این، در ایران هم ملاکهایی برای این موضوعات در نظر گرفته شده، که ما تمام این مصالح و متریال‌ها را بر اساس این استانداردها و آزمایش‌هایی که در آزمایشگاه‌های مصالح ساختمانی در ایران انجام می‌پذیرد، بررسی و در الیت‌زون عرضه می‌کنیم.
            </>
        )
    }

    const Answer11 = () => {
        return (
            <>
                در تشخیص ممتاز و معتبر بودن سوپلایرها، نخست به این نکته توجه خواهد شد که آیا مصالحی که آن‌ها تولید می‌کنند، از استاندارهای لازم برخوردار هست یا نه، که این مورد در پرسش قبلی پاسخ داده شد. موضوع بعدی این است که: آیا سوپلایرها توانِ تأمین مصالح ساختمانیِ مورد نیاز را بر اساس استاندارهای یادشده و با کیفیتِ موردنظر در حجم‌های بالایی که در الیت‌زون مورد نیاز و درخواست است، دارند یا نه؛ و آیا ناوگانِ حمل‌ونقلِ آن‌ها امکان حمل و رساندنِ این مصالح به اقصی‌نقاط ایران را دارند یا نه، که این دو فاکتور بر اساس آمارهای گذشته که از این سوپلایرها و تولیدکنندگان دریافت خواهد شد، بررسی و ارزیابی می‌شود.
                <br />
                برای تشخیص ممتاز و معتبر بودنِ سوپلایرها، فاکتورهای دیگری هم (همچون خوشنام بودنِ سوپلایرها و مانند آن) هست، که این آگاهی‌ها و اطلاعات را از طریق مصاحبه‌ها و مذاکره‌هایی که با دست‌اندرکاران صنعت ساختمان و توزیع پرسش‌نامه‌های ارزیابی در میان آن‌ها، گردآوری و بر اساس آن‌ها درباره‌ی سوپلایرها تصمیم‌گیری خواهیم کرد.

            </>
        )
    }

    const Answer12 = () => {
        return (
            <>
                عملکرد مهندسان و پیمانکاران در الیت‌زون به سه بخش تقسیم می‌شود: بخش نخست نقش پُررنگی است که به دلیل موقعیت شغلی خود دارند و هیچگاه از آن بهره نبرده‌اند. مهندسان و پیمانکاران دارای این ویژگی و مزیت هستند که کارفرمایان و سرمایه‌گذارانِ صنعت ساختمان و پروژه‌ها و نیازهای آن‌ها را به‌خوبی می‌شناسند و صرفاً داشتنِ همین اطلاعات، امتیازِ ویژه‌ای محسوب می‌شود که می‌توانند از مزیت‌های آن بهره‌مند شوند.
                <br />
                از سوی دیگر، توان فنی و دانشِ مهندسان و پیمانکاران آن‌ها را از عموم مردم جدا می‌سازد و با ارزیابی‌ها و فیدبک‌های دقیقِ خود می‌توانند نقش مؤثری در ارتقای سطح کیفی مصالح ساختمانی موجود داشته باشند و حتی پيشنهادهایی مؤثر برای تولید مصالح ساختمانیِ جدید به تولیدکنندگان بدهند. همچنین، اطلاعات مهندسان و پیمانکاران و اعتماد کارفرمایان به آن‌ها می‌تواند فضایی آرامش‌بخش و سرشار از اعتماد، از طریق مشاوره به کارفرمایان، فراهم آورد.
                <br />
                و در نهایت این که: شغل دلالی به خودیِ خود نه مذموم است و نه ارزشمند؛ بلکه به نحوه‌ی عملکردِ فرد در این حرفه بستگی دارد. در بسیاری از موارد، دلالی یا واسطه‌گری نقشی کلیدی در تسهیل معاملات، افزایش کارآییِ بازار و رساندن کالاها و خدمات به مصرف‌کنندگان دارد. برای مثال، در بازار املاک، خودرو، یا حتی مصالح ساختمانی، دلالان می‌توانند با ایجاد ارتباط میان عرضه‌کنندگان و خریداران، معاملات را آسان‌تر کنند. اما دلالی زمانی مذموم و نکوهیده تلقی می‌شود که فرد از ناآگاهیِ مشتریان سوءاستفاده کند، قیمت‌ها را به شکل غیرمنصفانه‌ای بالا ببرد، یا واسطه‌گری را به ابزاری برای احتکار و دست‌کاری بازار تبدیل کند. در مقابل، اگر دلال به شفافیت اطلاعات کمک کند، ارزش افزوده ایجاد کند و معامله‌ای منصفانه برای همه طرفین رقم بزند، می‌تواند شغلی مفید و حتی ضروری باشد.
                <br />
                در نهایت، اخلاق حرفه‌ای، صداقت و رعایت انصاف تعیین می‌کند که آیا یک دلالی خاص قابل احترام است یا نه.
            </>
        )
    }

    const Answer13 = () => {
        return (
            <>
            همه‌ی مراحلِ فرآیندها و قراردادهای الیت‌زون، بر اساس رضایت کارفرمایان (به‌عنوان مصرف‌کنندگان و مشتریان) بنا شده است. از همین رو، بسیار بعید است که اختلاف‌نظر جدی یا تنشی در گردش کار و در حین عملیات الیت‌زون بُروز نماید. اما در صورت بروز اختلاف میان اشخاص حقیقی و حقوقی در الیت‌زون، این‌گونه موارد در سه مرحله قابل حل و فصل خواهد بود:
            <br />
            در گام نخست، موضوع به مرکز تماس‌ها (Call Center) ارجاع می‌شود و همکاران ما خواهند کوشید که به حلِ مسأله کمک کنند. در مرحله‌ی دوم، موضوع به مرکز حل اختلاف در الیت‌زون ارجاع داده می‌شود؛ و در آخرین گام، چنانچه اختلاف همچنان حل نشده باشد، موضوع به‌صورت حقوقی دنبال خواهد شد. البته به‌طور کلی بعید است موارد اختلافی به مرحله‌ی سوم برسند.
            </>
        )
    }

    const Questions = [
        {
            id: 1,
            question: 'EliteZone چیست؟',
            answer: 'EliteZone یک سامانه آنلاین در خصوص خرید مصالح ساختمانی استاندارد است که با استفاده از پتانسیل مهندسان و پیمانکاران برای مشاوره به کارفرمایان، بازار بسیار بزرگی برای سوپلایرها (تامین کنندگان ممتاز) ایجاد می کند. این موضوع منجر به تامین مصالح ساختمانی با کیفیت بالا و قیمت رقابتی برای کارفرمایان، کاهش هزینه های بازاریابی برای سوپلایرها، و درآمد برای مهندسان و پیمانکاران می گردد.'
        },
        {
            id: 2,
            question: 'چه چیز موفقیت عملکرد EliteZone را تضمین می کند؟',
            answer: 'بنیانگذار EliteZone، گروه نرم افزاری سازه است. از سه دهه قبل، تقریبا تمامی پروژه های ساختمانی ایران توسط محصولات این گروه خصوصا نرم افزار SAZE90 طراحی می شود. این موضوع علاوه بر ایجاد اعتبار برای این گروه نزد جامعه مهندسان و پیمانکاران، دسترسی به همه بازار ساختمان ایران را میسر می سازد. این بازار بسیار بزرگ، تامین کنندگان معتبر را جذب و به این ترتیب چرخه استفاده از مصالح ساختمانی استاندارد تکمیل و موفقیت EliteZone تضمین می شود.'
        },
        {
            id: 3,
            question: 'حجم پروژه های مرتبط با نرم افزار SAZE90 چقدر است؟',
            answer: 'حجم پروژه های مرتبط با نرم افزار SAZE90 در سال بالغ بر ده ها میلیون مترمربع است که بازار بسیار بزرگی را برای سوپلایرها(تامین کنندگان معتبر) تعریف می کند. برنامه ریزی های انجام شده برای EliteZone همه پروژه های ساختمانی ایران را هدف قرار داده است.'
        },
        {
            id: 4,
            question: 'فرآیند خرید مصالح ساختمانی چگونه انجام می شود؟',
            answer: Answer4()
        },
        {
            id: 5,
            question: 'چرا یک مهندس یا یک پیمانکار از EliteZone استفاده می کند؟',
            answer: 'مهندسان یا پیمانکاران ذیصلاح بخوبی می دانند که ساختمان هائی که با استفاده از مصالح ساختمانی استاندارد و با کیفیت ساخته می شوند می تواند مسئولیت حرفه ای آنها در مقابل پروژه ها را پوشش دهد. همچنین مهندسان و پیمانکاران ذیصلاح، بخش مهمی از جامعه فرهیخته ایران هستند که انگیزه کافی برای کار بیشتر و کسب درآمد بیشتر دارند. EliteZone فرصت بی نظیری برای جامعه مهندسی ایران فراهم می آورد که از طریق آن بتوانند به مسئولیت های خود در قبال ساختمان ها بخوبی عمل کرده و در مقابل درآمد خود را به میزان قابل توجه افزایش دهند. مسیری که هیچگاه قبلا میسر نبوده است.'
        },
        {
            id: 6,
            question: 'چرا یک سوپلایر (تامین کننده ممتاز) از EliteZone استفاده می کند؟',
            answer: 'پس از فرایندهای تولید، تامین کنندگان همواره به دنبال بازاریابی موثر برای محصولات خود هستند و در این رابطه به میزان قابل توجهی هزینه می کنند. اگر یک تامین کننده معتبر مبتنی بر شواهد قابل اثبات بداند که به بازاری به بزرگی همه ایران دسترسی پیدا کرده است، حتما برای به دست آوردن چنین موقعیتی درنگ نخواهد کرد. بر اساس شواهد قابل ارائه، گروه نرم افزاری سازه ادعا می نماید که از طریق کاربران نرم افزارهای خود هم اکنون چنین بازاری را در اختیار دارد. لذا اقبال سوپلایرها به این بازار قطعی است.'
        },
        {
            id: 7,
            question: 'چرا یک کارفرما از EliteZone استفاده می کند؟',
            answer: 'کارفرمایان برای دریافت خدمات مهندسی و همچنین ساخت پروژه های خود مستقیما با مهندسان و پیمانکاران ذیصلاح در تماس هستند و از آنها در این رابطه مشورت می گیرند. همچنین کارفرمایان مایلند در ساختمان های خود از مصالح با کیفیت با قیمت های رقابتی استفاده کنند. EliteZone چنین امکانی را فراهم کرده است. کافیست مصالح ساختمانی ارائه شده در این سامانه توسط مهندسان و پیمانکاران برای خرید پیشنهاد شده و دلائل فنی و اقتصادی این خرید برای کارفرمایان محترم توجیه گردد. طبیعتاً خرید از این سامانه از هم از جهت کیفیت، هم از جهت قیمت و هم به دلیل اعتماد به مهندس یا پیمانکار پروژه برای کارفرما کاملا قابل توجیه است.'
        },
        {
            id: 8,
            question: 'آیا مهندسانی که می‌خواهند عضو الیت‌زون بشوند، حتماً می‌باید پروانه‌ی اشتغال به کار مهندسی داشته باشند؟',
            answer: Answer8()
        },
        {
            id: 9,
            question: 'آیا کاربران دیگر نرم‌افزارهای مهندسی (غیر از SAZE90) هم می‌توانند در الیت‌زون ثبت‌نام کنند و از مزایای آن بهره‌مند شوند؟',
            answer: Answer9()
        },
        {
            id: 10,
            question: 'معیار دست‌اندرکارانِ الیت‌زون برای تشخیصِ باکیفیت و ممتاز بودنِ مصالحی که در این سامانه عرضه خواهند شد، چیست؟',
            answer: Answer10()
        },
        {
            id: 11,
            question: 'معیارِ پایه‌گذاران و دست‌اندرکارانِ الیت‌زون برای تشخیصِ ممتاز و معتبر بودنِ سوپلایرها و تامین‌کنندگان چیست؟ ',
            answer: Answer11()
        },
        {
            id: 12,
            question: 'به نظر می‌رسد آن "مشاوره" و "پیشنهاد" که مهندسان به کارفرمایان ارائه خواهند داد و انتخاب مهندسان به‌عنوان "مشاور" از سوی کارفرمایان قدری مبهم است؛ آنچه این ابهام را برطرف و این "مشاوره" را از «مشارکت در سود» یا «دلالی» یا «درصدبگیری» متمایز می‌کند، چیست؟',
            answer: Answer12()
        },
        {
            id: 13,
            question: 'در صورت بروز هر گونه تنش یا اختلاف میان مهندسان، پیمانکاران، تامین‌کنندگان و کارفرمایان در خلال همکاری در الیت‌زون، چه سازوکار یا مرجعی برای رسیدگی به این اختلافات پیش‌بینی شده است؟',
            answer: Answer13()
        },
    ];

    const [ShowAllQuestion, setShowAllQuestion] = useState(false);

    const displayedQuestion = ShowAllQuestion ? Questions : Questions.slice(0, 5)

    const HandleDisplayedQuestion = () => {
        setShowAllQuestion(!ShowAllQuestion)
    }

    return (
        <div className='faq-container A_Style'>
            <div className='faq-content-container B_Style'>
                <div className='faq-content-wrapper'>
                    <div className='faq-title'>
                        <div>سوالات متداول</div>
                        <div> شما پرسیده اید... </div>
                    </div>

                    <AnimatePresence>
                        {displayedQuestion.map((question, index) => (
                            <motion.span key={index}
                                style={{ color: 'var(--Primary-EZ)', cursor: 'pointer' }}
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                transition={{ duration: .3, type: 'tween' }}
                            >
                                <AccordinBox question={question} index={index} />
                            </motion.span>
                        ))}
                    </AnimatePresence>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px', }}>
                        {Questions.length > 5 &&
                            // <ShortenerButton expanded={ShowAllQuestion} toggleExpand={HandleDisplayedQuestion} />
                            <button className='NotifShowMoreButton' onClick={HandleDisplayedQuestion}>
                                {ShowAllQuestion ?
                                    <div style={{ display: 'flex', alignItems: 'center', direction: 'rtl' }}>
                                        <div> بستن </div>
                                        <div style={{ display: 'inline-flex', marginRight: '8px', rotate: '90deg' }}> <img src={Flash2_TextEZ02_Icon} alt='img' style={{ width: '8px' }} /> </div>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', direction: 'rtl' }}>
                                        <div> نمایش همه سوالات متداول </div>
                                        <div style={{ display: 'inline-flex', marginRight: '8px', rotate: '-90deg' }}> <img src={Flash2_TextEZ02_Icon} alt='img' style={{ width: '8px' }} /> </div>
                                    </div>
                                }
                            </button>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default FAQ;
