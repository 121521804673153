import React, { useEffect, useRef, useContext, useState } from 'react';
import './SalesPlan1403.css';
import Navbar from '../Home/Components/Navbar';
import BreadCrumb from '../../PersonalFunction/BreadCrumb/BreadCrumb';
import { ResponsiveContext } from '../../Context/ResponsiveContext';
import Footer from '../Home/Components/Footer';
import LoadingDots from '../Auth/Components/LoadingDots';
import MainButton2 from '../../Buttons/Components/MainButton2';

function SalesPlan1403() {

    const { Under700 } = useContext(ResponsiveContext);

    const videoRef1 = useRef(null);

    const [SalesPlanVideo_Desktop, setSalesPlanVideo_Desktop] = useState(null);
    const [SalesPlanVideo_Mobile, setSalesPlanVideo_Mobile] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetch("https://elitezone.arvanvod.ir/Q9vyBeKeD6/r6J7zJ7KpB/origin_d7WZ5BBhrIZiZqd9L56jMimy4fpkbBeHL4D6JZtG.mp4")
            .then((response) => {
                if (!response.ok) throw new Error("Failed to load video");
                return response.blob();
            })
            .then((blob) => setSalesPlanVideo_Desktop(URL.createObjectURL(blob)))
            .catch((error) => {
                setError(true);
            });

        fetch("https://elitezone.arvanvod.ir/Q9vyBeKeD6/y0zaNza148/origin_1AQD06IO6XUoLNFovFh0mueKbmNHdRwN2GpbhhUJ.mp4")
            .then((response) => {
                if (!response.ok) throw new Error("Failed to load video");
                return response.blob();
            })
            .then((blob) => setSalesPlanVideo_Mobile(URL.createObjectURL(blob)))
            .catch((error) => {
                setError(true);
            });
    }, []);

    useEffect(() => {
        if (videoRef1.current) {
            videoRef1.current.src = Under700 ? SalesPlanVideo_Mobile : SalesPlanVideo_Desktop;
            videoRef1.current.load();
        }
    }, [Under700]);

    return (
        <>
            <Navbar />

            <BreadCrumb One={{ Link: '/saze90', Text: 'جشنواره فروش اسفند ماه SAZE90' }} Margin='20px auto 0 auto' />

            <div className='saze90_container A_Style'>

                <div className='saze90_style B_Style'>

                    <div className='saze90_video' onClick={() => window.open('https://www.saze90.com/get-your-badge/', '_blank')}>

                        {error ? (
                            <span style={{ color: "red", fontFamily: "yekanBakh-thin", fontSize: '12px' }}>خطایی در نمایش ویدیو پیش آمده است </span>
                        ) : SalesPlanVideo_Desktop && SalesPlanVideo_Mobile ? (
                            <video
                                ref={videoRef1}
                                muted
                                autoPlay
                                playsInline
                                src={Under700 ? SalesPlanVideo_Mobile : SalesPlanVideo_Desktop}
                            />
                        ) : (
                            <LoadingDots Color={'var(--Primary-EZ)'} />
                        )}

                    </div>

                    <div className='saze90_main_content'>

                        <span className='title'>
                            💫 اِلیت زون چیست؟
                        </span>
                        <span className='text'>
                            اِلیت زون منطقه نخبگان صنعت ساختمان ایران است!
                            <br />
                            مهندسان و پیمانکاران از طریق این سامانه  تأمین‌کنندگان ممتاز (سوپلایرها) را به کارفرمایان وصل می کنند تا کل بازار مصالح ساختمانی ایران اینجا شکل بگیرد. تنها در سال 1403 مساحت پروژه های در دسترس در
                            &zwnj;
                            <span className='link' onClick={() => window.open('https://elitezone.me/saze90-videos', '_blank')}>
                                EliteZone
                            </span>
                            &zwnj;
                            از طریق مهندسانِ کاربر نرم افزار SAZE90 ده ها میلیون مترمربع است!
                        </span>


                        <span className='title'>
                            🤔 گروه نرم افزاری سازه چه نقشی دارد؟
                        </span>
                        <span className='text'>
                            گروه نرم افزاری سازه از ۳ دهه پیش به راهکارهای بدیع خود در صنعت ساختمان شناخته شده است. با فشارهای اقتصادی ایجاد شده، این گروه راهی تازه
                            &zwnj;
                            <span className='link' onClick={() => window.open('https://elitezone.me/saze90-videos', '_blank')}>
                                (اِلیت زون)
                            </span>
                            &zwnj;
                            را برای استفاده از پتانسیل نهفته مهندسان و پیمانکاران برای کسب درآمد بسیار بیشتر ابداع کرده است.
                        </span>


                        <span className='title'>
                            ⚙️ تامین کنندگان، (برای نمونه در زمینه فولاد) چه نقشی دارند؟
                        </span>
                        <span className='text'>
                            تامین کنندگان ممتاز مصالح ساختمانی این پروژه ملی را تامین می کنند. اخیرا یکی از معتبرترین تامین کنندگان فولاد ایران به این پروژه پیوسته است.
                        </span>


                        <span className='title'>
                            🏅
                            &zwnj;
                            <span className='link' onClick={() => window.open('https://www.saze90.com/get-your-badge/', '_blank')}>
                                خرید نشان های طلایی، نقره ای، و برنزی
                            </span>
                            &zwnj;
                            در جشنواره اسفندماه چگونه کمک می‌کند؟
                        </span>

                        <span className='text'>
                            با تکمیل محصولات خود در جشنواره اسفندماه:
                            <br />
                            🔹️ تا 80% تخفیف بگیرید،
                            <br />
                            🔹️ از امکانات محصولات استفاده کنید،
                            <br />
                            🔹️ خدمات پشتیبانی خود را تا ۵ سال تمدید کنید،
                            <br />
                            🔹️ در خرید های آتی تخفیف قابل ملاحظه بگیرید،
                            <br />
                            🔹️ و نشان های طلایی، نقره‌ای و برنزی دریافت کنید.
                        </span>

                        <br />

                        <span className='text'>
                            با راه‌اندازی بخش خرید پروژه الیت زون  (که بزودی با فروش فولاد شروع خواهد شد) دارندگان
                            &zwnj;
                            <span className='link' onClick={() => window.open('https://www.saze90.com/get-your-badge/', '_blank')}>
                                نشان‌های طلایی، نقره ای، و برنزی
                            </span>
                            &zwnj;
                            بیشترین منافع را از آن خود می کنند. این موضوع پس از دریافت نشان در سرتیفیکیت صادر شده درج می گردد.
                        </span>


                        <div className='button' style={{ direction: 'ltr' }}>
                            <span className='info' onClick={() => window.open('https://elitezone.me/saze90-videos', '_blank')}>
                                <MainButton2 Text='اطلاعات بیشتر' ButtonMode="Info" ImgMode="Info" />
                            </span>
                            <span className='slaesplan_button' onClick={() => window.open('https://www.saze90.com/get-your-badge/', '_blank')}>
                                <MainButton2 Text='!در اِلیت‌زون بدرخشید' ButtonMode="SalesPlan" ImgMode="SalesPlan" />
                            </span>
                        </div>

                    </div>
                </div>
            </div >

            <Footer />

        </>
    )
}

export default SalesPlan1403