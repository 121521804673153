import React from 'react'
import './LoginButton.css';
import { useNavigate } from 'react-router-dom';

function LoginButton() {

  const navigate = useNavigate();

  return (
    <>
      <div className='Login_Button_Container' onClick={() => navigate('/auth')}>

        <div className='login-text-content'>

          <div>  
            ثبت نام 
            <span style={{ fontFamily: 'YekanBakh-Thin', fontWeight: 'bold', fontSize: '20px', padding: '0 6px' }}> | </span>
             ورود
          </div>

        </div>

      </div>
    </>
  )
}

export default LoginButton